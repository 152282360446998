import { useState } from "react";
import styles from "./Login.module.css";
import { sendOtp, verifyOtp } from "../../api/user";
import { useLocation, useNavigate } from "react-router-dom";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../utils/notification";
import { useAuth } from "./AuthProvider";
import Footer from "../Footer";
import Logo from "../Logo";
import isNumeric from "../../utils/isNumeric";

function Login() {
  const [loginDetails, setLoginDetails] = useState({
    userType: "",
    phoneNumber: "",
    otp: "",
  });

  const [isSendOtp, setIsSendOtp] = useState(true);
  const [isVerifyOtp, setIsVerifyOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);

  const navigate = useNavigate();
  const { login: updateLogin } = useAuth();

  const { state } = useLocation();
  const { redirectTo } = state || {};

  async function handleSendOtpClick(e) {
    e.preventDefault();
    setErrorMessage("");

    if (!loginDetails.userType) {
      notifyError(`select user type - Student or Teacher`);
      return;
    }

    if (!loginDetails.phoneNumber) {
      notifyError(`enter mobile number.`);
      return;
    }

    if (!isNumeric(loginDetails.phoneNumber)) {
      notifyError(`Phone number is not numeric.`);
      return;
    }

    if (loginDetails.phoneNumber.length !== 10) {
      notifyError(`Phone number must be of 10 digits.`);
      return;
    }

    setSendingOtp(true);

    const { statusCode, data, errorMessage } = await sendOtp(loginDetails);

    setSendingOtp(false);

    if (statusCode !== 200) {
      console.log(
        `Failed to send otp: statusCode: ${statusCode}, error: ${errorMessage}`
      );
      const errorMessageText =
        statusCode === 500 ? "Failed to send otp" : errorMessage;
      setErrorMessage(errorMessageText);
      return;
    }

    setIsSendOtp(false);
    setIsVerifyOtp(true);
  }

  async function handleSubmitOtpClick(e) {
    e.preventDefault();

    if (!loginDetails.otp) {
      alert(`Enter otp details`);
      return;
    }

    const { statusCode, data, errorMessage } = await verifyOtp(loginDetails);
    if (statusCode !== 200) {
      console.log(
        `Error in otp validation: statusCode: ${statusCode}, error: ${errorMessage}`
      );
      setErrorMessage(
        `${statusCode === 500 ? "Failed to verify otp" : errorMessage}`
      );
      return;
    }

    updateLogin(data.user, data.token);

    if (redirectTo) {
      navigate(`/${redirectTo}`);
    } else {
      navigate("/");
    }
    notifySuccess("Login Successful!");
  }

  function handleLoginDetailsChange(e) {
    setLoginDetails({
      ...loginDetails,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      <div className={styles.login__header}>
        <Logo logoSize="small" />
      </div>
      <main className={styles.login__main}>
        <div>Login</div>
        <form className={styles.login__form}>
          {isSendOtp && (
            <div className={styles.login__form__sendotp}>
              <div>
                <input
                  type="radio"
                  name="userType"
                  value="STUDENT"
                  checked={loginDetails.userType === "STUDENT"}
                  onChange={handleLoginDetailsChange}
                />
                <label htmlFor="student">Student</label>
                <input
                  type="radio"
                  name="userType"
                  value="TEACHER"
                  checked={loginDetails.userType === "TEACHER"}
                  onChange={handleLoginDetailsChange}
                />
                <label htmlFor="teacher">Teacher</label>
              </div>
              <div className={styles.login__form__mobile}>
                <label htmlFor="mobile">Mobile Number :</label>
                <br />
                <input
                  type="mobile"
                  id="mobile"
                  name="phoneNumber"
                  placeholder="Enter Mobile Number"
                  onChange={handleLoginDetailsChange}
                  value={loginDetails.phoneNumber}
                />
              </div>
              <div>
                <button
                  className={styles.sendotp__btn}
                  onClick={handleSendOtpClick}
                >
                  Send OTP
                </button>
              </div>
              {sendingOtp && <div>Sending Otp...</div>}
              {errorMessage && (
                <div className={styles.login__error}>{errorMessage}</div>
              )}
            </div>
          )}
          {isVerifyOtp && (
            <div className={styles.login__form__verifyotp}>
              <label htmlFor="otp">Enter OTP :</label>
              <input
                type="number"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                onChange={handleLoginDetailsChange}
                value={loginDetails.otp}
              />
              <div>
                <button onClick={handleSubmitOtpClick}>Submit OTP</button>
              </div>
              {errorMessage && (
                <div className={styles.login__error}>{errorMessage}</div>
              )}
            </div>
          )}
        </form>
      </main>

      <Footer />
    </>
  );
}

export default Login;
