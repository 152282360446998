import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import styles from "./Homepage.module.css";
import telephoneSVG from "../assets/telephone.svg";
import contactNumber from "../utils/contactNumber.js";

function HomePage() {
  const navigate = useNavigate();

  function handleFindTeacherOnClick() {
    navigate("/findTeacher");
  }

  function handleFindStudentOnClick() {
    navigate("/findStudent");
  }

  function handleContactNumberOnClick() {
    window.open(`tel:${contactNumber}`);
  }

  return (
    <div className={styles.homepage}>
      <Header />
      <main className={styles.homepage__main}>
        <div className={styles.findTutor}>Find Tutor/Student</div>
        <div onClick={handleContactNumberOnClick}>
          <img src={telephoneSVG} /> {contactNumber}
        </div>
        <div>
          <button
            className={styles.findStudent__btn}
            onClick={handleFindStudentOnClick}
          >
            Find Student
          </button>
        </div>
        <div>
          <button
            className={styles.findTeacher__btn}
            onClick={handleFindTeacherOnClick}
          >
            Find Teacher
          </button>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default HomePage;
