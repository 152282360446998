import styles from "./Footer.module.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.main_footer}>
      <div>Copyright {currentYear}</div>
    </footer>
  );
}

export default Footer;
