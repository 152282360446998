import apiService from "./apiService";

async function sendOtp(loginDetails) {
  return await apiService.post("/users/send-otp", loginDetails, false);
}

async function verifyOtp(loginDetails) {
  loginDetails.otp = Number(loginDetails.otp);
  return await apiService.post("/users/verify-otp", loginDetails, false);
}

export { sendOtp, verifyOtp };
