import apiService from "./apiService";

async function submitStudentFindingRequest(studentFindingDetails, userId) {
  return await apiService.post(
    `/teachers/${userId}/student-finding-request`,
    studentFindingDetails,
    true
  );
}

async function addTeachingSubjects(subjectDetails, userId) {
  return await apiService.post(
    `/teachers/${userId}/subjects`,
    subjectDetails,
    true
  );
}

export { submitStudentFindingRequest, addTeachingSubjects };
