class Response {
  constructor(statusCode, data, errorMessage) {
    this.statusCode = Number(statusCode);
    this.data = data;
    this.errorMessage = errorMessage;
  }
}

function successResponse(apiResponse) {
  return new Response(apiResponse.status, apiResponse.data.data);
}

function errorResponse(apiResponse) {
  let errorMessage = "";
  if (apiResponse.data.error) {
    errorMessage = apiResponse.data.error.message;
  } else {
    errorMessage = apiResponse.data;
  }

  return new Response(apiResponse.status, null, errorMessage);
}

export { successResponse, errorResponse };
