import { useEffect, useState } from "react";
import subjects from "../../utils/subjects";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../utils/notification";
import { addTeachingSubjects } from "../../api/teacher";
import { useAuth } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

function AddSubjects() {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState("");

  const selectedClassesInitialState = {
    NURSERY: false,
    KG: false,
    FIRST: false,
    SECOND: false,
    THIRD: false,
    FOURTH: false,
    FIFTH: false,
    SIXTH: false,
    SEVENTH: false,
    EIGHTH: false,
    NINTH: false,
    TENTH: false,
    ELEVENTH: false,
    TWELVE: false,
  };

  const subjectWithClassesInitialState = {};
  subjectWithClassesInitialState[""] = selectedClassesInitialState;
  subjects.forEach(
    (subject) =>
      (subjectWithClassesInitialState[subject] = selectedClassesInitialState)
  );
  const [subjectWithClasses, setSubjectWithClasses] = useState(
    subjectWithClassesInitialState
  );

  const [addedSubjects, setAddedSubjects] = useState([]);

  function handleClassCheckboxOnChange(e) {
    if (selectedSubject === "") {
      notifyWarning("Select any subject");
      return;
    }

    const updatedSelectedSubjectClasses = {
      ...subjectWithClasses[selectedSubject],
      [e.target.name]: !subjectWithClasses[selectedSubject][e.target.name],
    };

    setSubjectWithClasses((prevState) => ({
      ...prevState,
      [selectedSubject]: updatedSelectedSubjectClasses,
    }));
  }

  function handleAddSubjectOnClick(e) {
    e.preventDefault();
    if (!selectedSubject) {
      notifyWarning("Select any subject");
      return;
    }

    setAddedSubjects((prevState) => [...prevState, selectedSubject]);
  }

  function handleAddedSubjectOnClick(e, subject) {
    e.preventDefault();
    setSelectedSubject(subject);
  }

  function handleRemoveSubjectOnClick(e, subjectToRemove) {
    e.preventDefault();

    const updatedState = addedSubjects.filter(
      (subject) => subject !== subjectToRemove
    );
    setAddedSubjects([...updatedState]);

    setSelectedSubject("");
  }

  async function handleSaveSubjectsOnClick(e) {
    e.preventDefault();
    if (addedSubjects.length === 0) {
      notifyWarning(`Please add some subjects.`);
      return;
    }

    const subjectDetails = {};
    for (const subject of addedSubjects) {
      const classes = subjectWithClasses[subject];
      const selectedClassesList = [];
      for (const [clazz, isSelected] of Object.entries(classes)) {
        if (isSelected) {
          selectedClassesList.push(clazz);
        }
      }
      if (selectedClassesList.length === 0) {
        notifyWarning(`Select some class for subject : ${subject}`);
        setSelectedSubject(subject);
        return;
      }
      subjectDetails[subject] = selectedClassesList;
    }

    const { statusCode, data, errorMessage } = await addTeachingSubjects(
      subjectDetails,
      user.id
    );

    if (statusCode !== 200) {
      console.error(errorMessage);
      notifyError(`Failed to save subject details.`);
      return;
    }

    notifySuccess(data.message);
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirectTo: "addSubjects" } });
    }
  }, [isAuthenticated]);

  return (
    <>
      <Header />
      <form>
        <label htmlFor="subject">Choose a subject:</label>
        <select
          name="subjectSelection"
          id="subject"
          onChange={(e) => {
            setSelectedSubject(e.target.value);
          }}
          value={selectedSubject}
        >
          <option value="">Select Subject</option>
          {subjects.map((subject) => (
            <option value={subject}>{subject}</option>
          ))}
        </select>

        <div>
          {Object.keys(subjectWithClasses[selectedSubject]).map((clazz) => (
            <div>
              <input
                type="checkbox"
                name={clazz.toUpperCase()}
                onChange={handleClassCheckboxOnChange}
                checked={
                  subjectWithClasses[selectedSubject][clazz.toUpperCase()]
                }
              />
              <label>
                {clazz !== "KG"
                  ? `${clazz.charAt(0)}${clazz.substring(1).toLowerCase()}`
                  : "KG"}
              </label>
            </div>
          ))}
        </div>

        {!addedSubjects.includes(selectedSubject) && (
          <button onClick={handleAddSubjectOnClick}>Add Subject</button>
        )}
        {addedSubjects.includes(selectedSubject) && (
          <button disabled>Add Subject</button>
        )}
        <br />
        <button onClick={handleSaveSubjectsOnClick}>Save Subjects</button>
        <br />
        {addedSubjects.map((subject) => (
          <>
            <button onClick={(e) => handleAddedSubjectOnClick(e, subject)}>
              {subject}
            </button>
            <button onClick={(e) => handleRemoveSubjectOnClick(e, subject)}>
              X
            </button>
          </>
        ))}
      </form>
      <Footer />
    </>
  );
}

export default AddSubjects;
