import { ToastContainer } from "react-toastify";
import Homepage from "./pages/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Student from "./pages/Student";
import Teacher from "./pages/Teacher";
import { AuthProvider } from "./components/auth/AuthProvider";
import { FindTeacher } from "./components/student/FindTeacher";
import { FindStudent } from "./components/teacher/FindStudent";
import AddSubjects from "./components/teacher/AddSubjects";

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/student" element={<Student />} />
            <Route path="/teacher" element={<Teacher />} />
            <Route path="/findTeacher" element={<FindTeacher />} />
            <Route path="/findStudent" element={<FindStudent />} />
            <Route path="/addSubjects" element={<AddSubjects />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
