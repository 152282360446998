import { useNavigate } from "react-router-dom";

function Teacher() {
  const navigate = useNavigate();

  function handleFindStudentOnClick() {
    navigate("/findStudent");
  }

  return (
    <>
      <button onClick={handleFindStudentOnClick}>Find Student</button>
    </>
  );
}

export default Teacher;
