import { useNavigate } from "react-router-dom";
import styles from "./Logo.module.css";

function Logo({ logoSize }) {
  const navigate = useNavigate();

  function handleLogoOnClick() {
    navigate("/");
  }

  const logoSizeClass = `logo__brand__${logoSize}`;
  return (
    <div
      className={`${styles.logo__brand} ${styles[logoSizeClass]}`}
      onClick={handleLogoOnClick}
    >
      <span>AchyutamTutor</span>
    </div>
  );
}

export default Logo;
