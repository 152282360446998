import apiService from "./apiService";

async function submitTeacherFindingRequest(teacherFindingDetails, studentId) {
  return await apiService.post(
    `/students/${studentId}/teacher-finding-request`,
    teacherFindingDetails,
    true
  );
}

export { submitTeacherFindingRequest };
