import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { useEffect, useState } from "react";
import { submitTeacherFindingRequest } from "../../api/student";
import { notifySuccess, notifyWarning } from "../../utils/notification";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./FindTeacher.module.css";

function FindTeacher() {
  const [findTeacherDetails, setFindTeacherDetails] = useState({
    studentName: "",
    subjectName: "",
    class: "",
    location: "",
  });

  const [error, setError] = useState("");

  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  function handleFindTeacherDetailsChange(e) {
    setFindTeacherDetails({
      ...findTeacherDetails,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmitRequestOnClick(e) {
    e.preventDefault();
    setError("");

    if (!findTeacherDetails.studentName) {
      notifyWarning(`Enter student name`);
      return;
    }

    if (!findTeacherDetails.subjectName) {
      notifyWarning(`Enter subject name`);
      return;
    }

    if (!findTeacherDetails.class) {
      notifyWarning(`Enter class details.`);
      return;
    }

    if (!findTeacherDetails.location) {
      notifyWarning(`Enter location detail.`);
      return;
    }

    const { statusCode, errorMessage } = await submitTeacherFindingRequest(
      findTeacherDetails,
      user.id,
      true
    );

    if (statusCode !== 200) {
      setError(errorMessage ? errorMessage : "Failed to submit request.");
    } else {
      setError("");
      notifySuccess("Request sumbitted successfully");
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirectTo: "findTeacher" } });
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.findTeacherContainer}>
      <Header />
      <main className={styles.findTeacherMain}>
        <div>Find Teacher Request</div>
        <form className={styles.findTeacherForm}>
          <input
            onChange={handleFindTeacherDetailsChange}
            type="text"
            placeholder="Enter Student Name"
            name="studentName"
            value={findTeacherDetails.studentName}
          />
          <input
            onChange={handleFindTeacherDetailsChange}
            type="text"
            placeholder="Enter Subject"
            name="subjectName"
            value={findTeacherDetails.subject}
          />
          <input
            onChange={handleFindTeacherDetailsChange}
            type="text"
            placeholder="Enter Class"
            name="class"
            value={findTeacherDetails.class}
          />
          <input
            onChange={handleFindTeacherDetailsChange}
            type="text"
            placeholder="Enter your location"
            name="location"
            value={findTeacherDetails.location}
          />
          <button onClick={handleSubmitRequestOnClick}>Submit Request</button>
        </form>
        {error && <div>{error}</div>}
      </main>
      <Footer />
    </div>
  );
}

export { FindTeacher };
