import axios from "axios";
import { successResponse, errorResponse } from "./responseHandler";

const baseURL = "https://tuition-finder-backend.onrender.com";

async function post(apiPath, body, authRequired) {
  try {
    const response = await axios.post(
      `${baseURL}${apiPath}`,
      body,
      authRequired ? getAuthConfig() : {}
    );
    console.debug(response);
    return successResponse(response);
  } catch (error) {
    console.error(error);
    if (error.response) {
      return errorResponse(error.response);
    }

    throw error;
  }
}

function getAuthConfig() {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return config;
}

const apiService = {
  post,
};

export { baseURL };
export default apiService;
