import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function notifySuccess(message) {
  toast.success(message);
}

function notifyError(message) {
  toast.error(message);
}

function notifyWarning(message) {
  toast.warn(message);
}

export { notifySuccess, notifyError, notifyWarning };
