import { useNavigate } from "react-router-dom";

function Student() {
  const navigate = useNavigate();

  function handleFindTeacherOnClick() {
    navigate("/findTeacher");
  }

  return (
    <>
      <button onClick={handleFindTeacherOnClick}>Find Teacher</button>
    </>
  );
}

export default Student;
