const subjects = [
  "Science",
  "Hindi",
  "English",
  "Maths",
  "Computer Science",
  "Chemistry",
  "Physics",
  "Biology",
  "SST",
  "Accountancy",
  "Economics",
  "Business Studies",
  "All Subjects",
];

export default subjects;
