import { useAuth } from "./auth/AuthProvider";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";

function Header() {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  function handleLoginOnClick() {
    navigate("/login");
  }

  function handleLogoutOnClick() {
    logout();
  }

  return (
    <header className={styles.main_header}>
      <Logo logoSize="small" />
      <nav>
        {!isAuthenticated && (
          <button onClick={handleLoginOnClick}>Login</button>
        )}
        {isAuthenticated && (
          <button onClick={handleLogoutOnClick}>Logout</button>
        )}
      </nav>
    </header>
  );
}

export default Header;
