import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { useEffect, useState } from "react";
import { submitStudentFindingRequest } from "../../api/teacher";
import { notifySuccess, notifyWarning } from "../../utils/notification";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./FindStudent.module.css";

function FindStudent() {
  const [findStudentDetails, setFindStudentDetails] = useState({
    teacherName: "",
    subjectName: "",
    class: "",
    locations: "",
  });
  const [error, setError] = useState("");

  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  function handleFindStudentDetailsChange(e) {
    setFindStudentDetails({
      ...findStudentDetails,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmitRequestOnClick(e) {
    e.preventDefault();
    setError("");

    if (!findStudentDetails.teacherName) {
      notifyWarning("Enter teacher name");
      return;
    }

    if (!findStudentDetails.subjectName) {
      notifyWarning("Enter subject name");
      return;
    }

    if (!findStudentDetails.class) {
      notifyWarning("Enter class detail.");
      return;
    }

    if (!findStudentDetails.locations) {
      notifyWarning("Enter location detail.");
      return;
    }

    const { statusCode, errorMessage } = await submitStudentFindingRequest(
      findStudentDetails,
      user.id
    );

    console.debug(`status code : ${statusCode}`);
    if (statusCode !== 200) {
      setError(errorMessage ? errorMessage : "Failed to submit request.");
    } else {
      setError("");
      notifySuccess("Request sumbitted successfully");
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirectTo: "findStudent" } });
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.findStudentContainer}>
      <Header />
      <main className={styles.findStudentMain}>
        <div>Find Student Request</div>
        <form className={styles.findStudentForm}>
          <input
            onChange={handleFindStudentDetailsChange}
            type="text"
            name="teacherName"
            placeholder="Enter teacher name"
            value={findStudentDetails.subjectToTeach}
          />
          <input
            onChange={handleFindStudentDetailsChange}
            type="text"
            name="subjectName"
            placeholder="Enter Subject to teach"
            value={findStudentDetails.subjectToTeach}
          />
          <input
            onChange={handleFindStudentDetailsChange}
            type="text"
            name="class"
            placeholder="Enter Class, like - kg, first, second, .."
            value={findStudentDetails.class}
          />
          <input
            onChange={handleFindStudentDetailsChange}
            type="text"
            name="locations"
            placeholder="Enter teaching locations, like - location1, location2"
            value={findStudentDetails.locations}
          />
          <button onClick={handleSubmitRequestOnClick}>Submit Request</button>
        </form>
        {error && <div>{error}</div>}
      </main>
      <Footer />
    </div>
  );
}

export { FindStudent };
