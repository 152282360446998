import { createContext, useContext, useReducer } from "react";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  token: localStorage.getItem("token"),
  isAuthenticated: localStorage.getItem("token") ? true : false,
};

const AuthContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case "LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    default:
      throw new Error(`Unknow action type ${action.type} for authentication`);
  }
}

function AuthProvider({ children }) {
  const [{ user, token, isAuthenticated }, dispatch] = useReducer(
    reducer,
    initialState
  );

  function login(user, token) {
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        token,
      },
    });
  }

  function logout() {
    dispatch({
      type: "LOGOUT",
    });
  }

  return (
    <AuthContext.Provider
      value={{ user, token, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("AuthContext used out of scope.");
  }

  return context;
}

export { AuthProvider, useAuth };
